@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./swiper.css');

.dropdown {
    position: absolute;
    background: white;
    top: 45px;
    right: 0;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    min-width: 60%;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
}

.dropdown_link {
    margin: 0.5rem;
    color: black;
}

/* .react-icons {
    vertical-align: middle;

} */

.district-swiper-slide {
    width: 400px !important;
    height: 320px !important;

}

.property-swiper-slide {
	width: 250px !important;
	margin-right: 10px;
}
	

.district-swiper-large-image {
    height: 300px;
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
}

.district-swiper-small-image {
    /* padding-bottom: 5px !important; */
    height: 150px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */

}

/* START: search bts styles */
.search-bts {
    @apply flex-1 max-sm:w-full flex justify-start items-center;
}

.search-bts__input {
    @apply w-full h-[48px] pl-12 p-4 rounded-l-full max-sm:rounded-full bg-light outline-none cursor-pointer text-sm;
}

.search-bts__options {
    @apply absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-light py-1 text-base shadow-lg ring-1 ring-maincolor ring-opacity-5 focus:outline-none sm:text-sm;
}

.search-bts__option {
    @apply cursor-default select-none py-2 pl-10 pr-4;
}

/* END: search bts styles */

/* START: Custom Filter styles */
.custom-filter__btn {
  @apply relative w-full min-w-[127px] flex justify-between items-center cursor-default rounded-lg bg-white py-2 px-3 text-left shadow-md sm:text-sm border;
}

.custom-filter__options {
  @apply absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm;
}
/* END: Custom Filter styles */

/*.swiper-button-next,
.swiper-button-prev {
  color: maincolor; 
}*/


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
    display: none;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* Custom CSS for scrollbar (Firefox) */
.scrollable {
  scrollbar-width: thin; /* Make the scrollbar thin */
  scrollbar-color: #6b7280 #4b5563; /* Set scrollbar track and thumb colors */
}

.scrollable::-webkit-scrollbar {
  width: 0; /* Disable WebKit scrollbar for Firefox */
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: transparent; /* Disable WebKit scrollbar thumb for Firefox */
}

.scrollable::-webkit-scrollbar {
    width: 8px; /* Adjust the width as needed */
  }
  
.scrollable::-webkit-scrollbar-thumb {
    @apply bg-maincolor; /* Set your desired scrollbar color */
    border-radius: 4px;
  }
  
.scrollable::-webkit-scrollbar-thumb:hover {
    @apply bg-secondary; /* Change the color on hover if desired */
}




