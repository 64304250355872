:root {
    --swiper-navigation-color: #233142;
}

.swiper-button-next,
.swiper-button-prev {
    width: 40px !important;
    height: 40px !important;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    /* Change background color to red */
}
.new .swiper-button-next,
.new .swiper-button-prev {
    width: 28px !important;
    height: 28px !important;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    /* Change background color to red */
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
    background-color: rgba(0, 0, 0, 0.8);
    transition-duration: 300ms;
    border: #AD916A 1px solid;
    /* Change background color to red */
}

.swiper-button-prev, .swiper-button-next {
    color: #AD916A !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 24px !important; /* Adjust the size of the arrow icon */
}

.new .swiper-button-prev::after,
.new .swiper-button-next::after {
  font-size: 18px !important; /* Adjust the size of the arrow icon */
}